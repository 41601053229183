.user-stats {
  text-align: right;

  p {
    font-size: 20px;
    margin: 1px;
  }
}

@media screen and (max-width: 768px) {
  .user-stats {
    p {
      font-size: 14px;
      margin: 1px;
    }
  }
}