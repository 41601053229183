.pool-tracker {
  text-align: center;
  margin: 20px;
  font-size: 36px;

  h3 {
    margin: 25px 0;
  }

  .enter-username {
    input[type="text"] {
      text-align: center;
      padding: 10px;
      font-size: 32px;
      width: 280px;
      margin-bottom: 45px;
      border: 1px solid;
      border-radius: 10px;
    }

    .login-button {
      font-size: 24px;
    }
  }

  button {
    border-radius: 10px;
    font-size: 30px;
    cursor: pointer;
  }

  /* Button box makes height match width */
  .button-box {
    position: relative;
    width: 24%;
    height: auto;

    button {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      min-height: 33px;
      min-width: 33px;
    }

    button.ball-down-on-break {
      .yes-or-no {
        font-weight: bold;
        margin: 10px 0 0;
      }
      .press-to-toggle {
        font-size: 16px;
      }
    }

    .mini-score {
      font-size: 18px;
    }
  }

  .button-box:before {
    content: "";
    display: block;
    /* Padding Top 100% gives ratio of 1:1 */
    padding-top: 100%;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    .header-left {
      display: flex;
      flex-direction: column;

      h3 {
        margin: 10px 0 20px;
      }

      button.show-stats {
        font-size: 20px;
        max-width: 180px;
        padding: 5px;
      }
    }
  }

  .tracker-buttons {
    display: flex;
    justify-content: space-between;
    
    .button-box {
      button.pot-missed {
        background: lightpink;
      }
    
      button.position-missed {
        background: lightyellow;
      }
    
      button.foul {
        background: lightcoral;
      }
    }
  }

  .current-score {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 37%;
    padding-right: 37%;

    .score {
      font-size: 46px;
    }
  }

  .submit-section {
    margin-top: 40px;

    button.submit-button {
      background: lightgreen;
      width: 20%;
      min-width: 100px;
      height: 70px;
    }
  }
}

@media screen and (max-width: 768px) {
  .pool-tracker {
    font-size: 18px;
  
    button {
      font-size: 14px;
    }

    .button-box {
      .mini-score {
        font-size: 12px;
      }
    }

    .header {
      .header-left {
        button.show-stats {
          font-size: 16px;
          max-width: 215px;
          padding: 5px;
        }
      }
    }

    .current-score {
      padding-left: 30%;
      padding-right: 30%;

      .score {
        font-size: 24px;
      }
    }

    .submit-section {
      margin-top: 20px;

      button.submit-button {
        height: 60px;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .pool-tracker {
  
    button {
      font-size: 10px;
    }

    .button-box {
      .mini-score {
        font-size: 8px;
      }
  
      button.ball-down-on-break {
        .yes-or-no {
          margin: 2px 0 0;
        }
        .press-to-toggle {
          font-size: 10px;
        }
      }
    }
  }
}