.all-user-stats {
  position: fixed;
  top: 150px;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  height: 100vh;

  .stats-modal {
    margin: 30px;
    padding: 40px;
    background: white;
    border: 1px solid black;
    border-radius: 25px;
    word-break: break-all;
    font-size: 18px;
    max-height: calc(100vh - 290px);
    overflow-y: auto;

    table {
      width: 100%;
      border-collapse: collapse;

      .group-title {
        font-weight: bold;
      }

      thead {
        td {
          font-weight: bold;
        }
      }

      span.total {
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .all-user-stats {
    .stats-modal{
      font-size: 12px;
    }
  }
}